import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const ButtonFixed = ({ button_link_fixed, isFixed }) => {

  if (!button_link_fixed) return null

  return (
    button_link_fixed && (
      <div
        className={`container-btn-fixed ${isFixed && "container-btn-fixed--static"
          }`}
      >
        <div
          className="hand container-btn-fixed__btn"
        >
          {documentToReactComponents(button_link_fixed.json)}
        </div>
      </div>
    )
  )
}

export default ButtonFixed
