import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { CloseOutlined } from '@ant-design/icons';

import { liftersView as LiftersViewActions } from "../../../../services/LiftersView/LiftersViewActions"
import { useContenfulNewLead } from "../../../../contenful/querys/Forms/FormNewLead"
import FormPersonNatural from "./components/FormPersonNatural/FormPersonNatural"
import FormPersonJuridical from "./components/FormPersonJuridical/FormPersonJuridical"

const FormSteps = ({  formTitle, handleToggleForm }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [step, setStep] = useState(1)
  const { languageISO639 } = useSelector(state => state.language)
  const { newLead, ignoreDataLead } = useSelector(state => state.liftersView)
  const contenfulNewLead = useContenfulNewLead(languageISO639)

  const closeModal = () => {
    if (handleToggleForm) 
      handleToggleForm(false)
    else 
      dispatch(LiftersViewActions.setFormSteps(false))
  }

  const handlerStep = newStep => setStep(newStep)

  if (!contenfulNewLead) return null

  return (
    <>
      <div className="form-steps__fix">
        <h2 className="form-lifters__title">{formTitle}</h2>
        <div className="form-steps__fixCloseCont" onClick={closeModal}>
          <div className="form-steps__fixClose" />
          <CloseOutlined className="icon-close-form-lifters"/>
        </div>
      </div>
      <div className="form-steps__fixProgress">
        <p className="form-steps__fixProgress__name-step step-active">
          {t("general.step1")}
          <span className="form-steps__fixProgress__name-stepLine" />
        </p>
        {newLead?.type_person === t("general.natural") && (
          <p
            className={`form-steps__fixProgress__name-step ${
              step === 2 && "step-active"
            }`}
          >
            {t("general.step2")}
            <span className="form-steps__fixProgress__name-stepLine" />
          </p>
        )}
      </div>
      <div
        className="form-steps__content"
        style={{ marginTop: ignoreDataLead && !newLead ? 15 : 0 }}
      >
        {newLead?.type_person === t("general.natural") && (
          <FormPersonNatural
            handlerStep={handlerStep}
            handlerCloseModal={closeModal}
            contenfulNewLead={contenfulNewLead}
          />
        )}
        {newLead?.type_person === t("general.juridical") && (
          <FormPersonJuridical 
            handlerCloseModal={closeModal}
            contenfulNewLead={contenfulNewLead} 
          />
        )}
      </div>
    </>
  )
}

export default FormSteps
