import React, { useEffect, useState } from "react"
import { Collapse, Row, Col } from "antd"
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

import { CardIntercom } from "../../Cards/CardIntercom/CardIntercom"

const { Panel } = Collapse

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, next) =>
      `<span>${next(node.content)}</span>`
  }
}

const Faq = ({ question_title, frequent_questions }) => {

  const [showCardIntercom, setShowCardIntercom] = useState(true)

  useEffect(() => {
    if (window.Intercom) {
      window.Intercom("onHide", () => setShowCardIntercom(true))
      window.Intercom("onShow", () => setShowCardIntercom(false))
    }
  }, [])

  return (
    <div className="container">
      <div className="section-faq">
        <Row className="rows">
          <Col lg={14} xs={24} className={`${!showCardIntercom && "row-list"}`}>
            <h2 className="section-faq__title"> {question_title} </h2>
            <Collapse
              bordered={false}
              defaultActiveKey={["0"]}
              expandIconPosition="right"
            >
              {frequent_questions?.map((item, index) => (
                <Panel header={item.question} key={index}>
                  <div className="paragraph" dangerouslySetInnerHTML={{ __html: documentToHtmlString(item.answer?.json, options) }} />
                </Panel>
              ))}
            </Collapse>
          </Col>
          {showCardIntercom && (
            <Col lg={10} xs={24}>
              <CardIntercom />
            </Col>
          )}
        </Row>
      </div>
    </div>
  )
}

export default Faq
