import React, { useState } from "react"
import { Row, Col } from "antd"

import IconEducation from "../../../assets/images/education.png"
import IconSecurity from "../../../assets/images/security.png"
import IconSurprise from "../../../assets/images/surprise.png"
import IconMoney from "../../../assets/images/money.png"
import IconQuality from "../../../assets/images/quality.png"
import { CaourselMobile } from "./components/CarouselMobile"

const initialOrder = ["first", "second", "third", "fourth", "main"]
let order = ["first", "second", "third", "fourth", "main"]
const settings = {
  first: {
    left: 310,
    top: 0,
  },
  second: {
    left: 360,
    top: 80,
  },
  third: {
    left: 360,
    top: 160,
  },
  fourth: {
    left: 310,
    top: 240,
  },
  main: {
    left: 90,
    top: 135,
  },
}

function move(e) {
  let { target } = e

  if (target.tagName !== "DIV") target = target.parentElement

  const startIndex = order.findIndex(item => item === target.id)

  const newOrder = order.filter(item => item !== target.id)
  newOrder.push(order[startIndex])

  document.getElementById(
    order[order.length - 1]
  ).childNodes[1].style.opacity = 1
  document.getElementById(
    order[order.length - 1]
  ).childNodes[0].style.transform = "scale(0.7)"

  newOrder.forEach((item, i) => {
    const element = document.getElementById(item)
    element.className = "secundaryTransition"
    element.style.top = `${settings[initialOrder[i]].top}px`
    element.style.left = `${settings[initialOrder[i]].left}px`
  })

  target.className = "mainTransition"
  target.style.top = order[order.length - 1].top
  target.style.left = order[order.length - 1].left
  target.childNodes[1].style.opacity = 0
  target.childNodes[0].style.transform = "scale(2.5)"
  order = newOrder
}

function Benefits({ benefits_title, benefits }) {
  const [itemSelected, setItemSelected] = useState()

  const itemClick = (e, item) => {
    move(e)
    setItemSelected(item)
  }

  const load = benefits?.find(item => item.key === "load")
  const security = benefits?.find(item => item.key === "security")
  const surprise = benefits?.find(item => item.key === "surprise")
  const training = benefits?.find(item => item.key === "capacity")
  const biweekly = benefits?.find(item => item.key === "biweekly")

  return (
    <div className="benefits_container">
      <div className="section-benefits" data-aos="fade-up">
        <Row style={{ width: "100%" }}>
          <Col sm={12} className="section-benefits--mobile">
            <CaourselMobile benefits={benefits} handlerSetItemSelect={setItemSelected}/>
          </Col>
          <Col lg={10} sm={24} className="section-benefits__content">
            <h2 className="section-benefits__content__title">
              {benefits_title}
            </h2>
            <h4 className="section-benefits__content__subtitle">
              {itemSelected?.title || biweekly?.title}
            </h4>
            <hr className="section-benefits__content__hr" />
            <p className="section-benefits__content__descrip">
              {itemSelected?.description?.description ||
                biweekly?.description?.description}
            </p>
          </Col>
          {benefits && (
            <Col lg={14} sm={24} className="section-benefits__items">
              <div className="section-benefits__animation">
                <div
                  id="main"
                  className="mainTransition"
                  onClick={e => itemClick(e, biweekly)}
                  style={{ top: settings.main.top, left: settings.main.left }}
                >
                  <img src={IconMoney} alt="" />
                  <p>{biweekly.name}</p>
                </div>
                <div
                  id="first"
                  onClick={e => itemClick(e, load)}
                  className="secundaryTransition"
                  style={{ top: settings.first.top, left: settings.first.left }}
                >
                  <img src={IconEducation} alt="" />
                  <p>{load.name}</p>
                </div>
                <div
                  id="second"
                  onClick={e => itemClick(e, security)}
                  className="secundaryTransition"
                  style={{
                    top: settings.second.top,
                    left: settings.second.left,
                  }}
                >
                  <img src={IconSecurity} alt="" />
                  <p>{security.name}</p>
                </div>
                <div
                  id="third"
                  onClick={e => itemClick(e, surprise)}
                  className="secundaryTransition"
                  style={{ top: settings.third.top, left: settings.third.left }}
                >
                  <img src={IconSurprise} alt="" />
                  <p>{surprise.name}</p>
                </div>
                <div
                  id="fourth"
                  onClick={e => itemClick(e, training)}
                  className="secundaryTransition"
                  style={{
                    top: settings.fourth.top,
                    left: settings.fourth.left,
                  }}
                >
                  <img src={IconQuality} alt="" />
                  <p>{training.name}</p>
                </div>
              </div>
            </Col>
          )}
        </Row>
      </div>
    </div>
  )
}

export default Benefits
