import React from "react"
import { Button } from "antd"
import { useTranslation } from "react-i18next"

import people from "../../../assets/images/people.png"
import logo from "../../../assets/images/logointercom.png"
import logoDark from "../../../assets/images/logodarkintercon.png"
import message from "../../../assets/images/mensage.png"
import salu from "../../../assets/images/saludo.png"

export const CardIntercom = () => {
  const { t } = useTranslation()

  const handlerIntercom = () => {
    if (window.Intercom) window.Intercom("show")
  }

  return (
    <div className="CardIntercom">
      <div className="CardIntercom__center">
        <div className="CardIntercom__head">
          <img className="logo" src={logo} alt="logo intercom" />
          <div className="CardIntercom__head--welcome">
            <h1 className="hi">{t("card.intercom.hi")}</h1>
            <img className="img-hi" src={salu} alt="hi" />
          </div>
          <p className="CardIntercom__head--text">{t("card.intercom.title")}</p>
        </div>
        <div className="CardIntercom__body">
          <div className="CardIntercom__body__people">
            <h3 className="title">{t("card.intercom.start")}</h3>
            <p className="text">{t("card.intercom.response")}</p>
            <img src={people} alt="people" />
            <Button className="send" onClick={handlerIntercom}>
              <img className="img" src={message} alt="send" />
              {t("card.intercom.send")}
            </Button>
          </div>
        </div>
        <div className="CardIntercom__footer">
          <img className="img-hi" src={logoDark} alt="logo dark" />
          <p>We run on Intercom</p>
        </div>
      </div>
    </div>
  )
}
