import React, { useState } from "react"
import ReactPlayer from "react-player"
import { Button } from "antd";

import playVideo from "../../../assets/images/play-videos.png"

function Video({ video_title, video_subtitle, video_about }) {
  const [autoPlay, setAutoPlay] = useState(false)
  const [play, setPlay] = useState(false)
  const [pause, setPause] = useState(false)

  const handleVideo = item => {
    setTabActive(item)
    setPlay(false)
    setPause(false)
  }

  const handleVideoPause = () => {
    setPlay(false)
    setPause(true)
  }

  return (
    <div className="player-container">
      <div className="player-wrapper">
        <ReactPlayer
          url={video_about}
          className="react-player"
          onPlay={() => setPlay(true)}
          onPause={handleVideoPause}
          playing={play}
          width="100%"
          height="100%"
          config={{
            youtube: {
              playerVars: {
                controls: 0,
                fs: 0,
                modestbranding: 0,
              },
            },
          }}
        />
        {!play &&
          <Button className="play" onClick={() => setPlay(true)}>
            <img src={playVideo} alt="play" />
          </Button>
        }
      </div>
      <div
        className={`player-wrapper__content ${autoPlay === true
            ? "player-wrapper__contentHide"
            : "player-wrapper__contentShow"
          }`}
      >
        <div className="player-wrapper__content__wrap">
          <h2 className="player-wrapper__content__wrapTitle">{video_title}</h2>
          <p className="player-wrapper__content__wrapDescrip">
            {video_subtitle}
          </p>
          <div
            className="player-wrapper__content__play"
            onClick={() => setAutoPlay(true)}
          >
            <div className="player-wrapper__content__playIcon" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Video
