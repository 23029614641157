import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { Row, Col } from "antd"
import BackgroundImage from "gatsby-background-image"
import { useInView } from "react-intersection-observer"
import { Link, Element } from "react-scroll"
import { CSSTransition } from "react-transition-group"

import Layout from "../../components/Layout/LayoutPublic/LayoutPublic"
import VideoLifters from "../../components/PageLifters/Video/Video"
import Benefits from "../../components/PageLifters/Benefits/Benefits"
import OurApp from "../../components/PageLifters/OurApp/OurApp"
import University from "../../components/PageLifters/University/University"
import Faq from "../../components/PageLifters/Faq/Faq"
import Followus from "../../components/FollowUs/FollowUs"
import ButtonFixed from "../../components/PageLifters/ButtonFixed/ButtonFixed"
import SectionWeLifters from "../../components/PageLifters/SectionWeLifters/SectionWeLifters"
import SEO from "../../components/seo"
import FormNewLead from "../../components/PageLifters/FormNewLead/FormNewLead"
import { liftersView as LiftersViewActions } from "../../services/LiftersView/LiftersViewActions"

const Lifters = ({
  data: { contentfulPaginaLifters: contentfulPageLifters },
}) => {
  const protocol =
    typeof window !== "undefined" ? window.location.protocol : "https:"

  const [banner, inBanner] = useInView({ threshold: 0 })
  const [caroucel, inCaroucel] = useInView({ threshold: 0 })

  const dispatch = useDispatch()
  const [inFooter, setInFooter] = useState()
  const [openFormModal, setOpenFormModal] = useState(false)
  const { languageISO639 } = useSelector(state => state.language)

  useEffect(() => {
    if (openFormModal) document.body.style.overflow = "hidden"
    else document.body.style.overflow = null
  }, [openFormModal])

  const handlerInFooter = _inFooter => {
    setInFooter(_inFooter)
  }

  const handleToggleForm = open => {
    setOpenFormModal(open)
  }

  if (!contentfulPageLifters) return null

  const {
    seo,
    bannerImg,
    banner_title,
    subtitle_banner,
    mapaApp,
  } = contentfulPageLifters

  return (
    <Layout handlerInFooter={handlerInFooter}>
      <SEO {...seo} lang={languageISO639} />
      <CSSTransition
        in={openFormModal}
        timeout={500}
        classNames="page-lifter-form-anim"
        onExited={() => dispatch(LiftersViewActions.setFormSteps(false))}
      >
        <div className="page-lifter-form">
          <FormNewLead
            {...contentfulPageLifters}
            handleToggleForm={handleToggleForm}
          />
        </div>
      </CSSTransition>
      <section className="page-lifter">
        <BackgroundImage
          Tag="section"
          fluid={bannerImg.fluid}
          background="linear-gradient(360deg, rgb(7, 8, 15) -0.19%, rgba(0, 0, 0, 0.2) 21.78%, rgba(0, 0, 0, 0) 100%)"
          className="hola"
          style={{ position: "relative", zIndex: "24", backgroundAttachment: "fixed" }}
        >
          <div className="back">

          </div>
          <div className="page-lifter__banner">
            <div className="page-lifter__banner__container">
              <div className="page-lifter__banner__content">
                <h1>{banner_title}</h1>
                <Link to="video" offset={-90} spy smooth duration={1000}>
                  <div className="page-lifter__banner__scroll">
                    <h3>{subtitle_banner}</h3>
                    <div className="page-lifter__banner__scroll__arrows">
                      <div className="page-lifter__banner__scroll__arrowsArrow" />
                      <div className="page-lifter__banner__scroll__arrowsArrow" />
                      <div className="page-lifter__banner__scroll__arrowsArrow" />
                    </div>
                  </div>
                </Link>
              </div>
              <div className="page-lifter__banner__form">
                {!openFormModal && <FormNewLead {...contentfulPageLifters} />}
              </div>
            </div>
          </div>
        </BackgroundImage>
        <section ref={banner} className={`${openFormModal && "modal--open"}`}>
          <div className="content-video">
            <div data-aos="fade-up" className="page-lifter__video">
              <div className="container">
                <Row>
                  <Col span={24}>
                    <Element name="video">
                      <VideoLifters {...contentfulPageLifters} />
                    </Element>
                  </Col>
                </Row>
              </div>
            </div>
          </div>

          <div className="page-lifter__benefits">
            <Benefits {...contentfulPageLifters} />
          </div>

          <div
            className="page-lifter__ourApp"
          // style={{
          //   background: `linear-gradient(360deg, rgb(7, 8, 15) -0.19%, rgba(0, 0, 0, 0.2) 21.78%, rgba(0, 0, 0, 0) 100%)`,
          // }}
          >
            <div className="animation-points">
              <div className="animation-points__point" />
              <div className="animation-points__point" />
              <div className="animation-points__point" />
              <div className="animation-points__point" />
              <div className="animation-points__point" />
            </div>
            <OurApp {...contentfulPageLifters} />
            <div>
              <SectionWeLifters {...contentfulPageLifters} />
            </div>
          </div>

          <div ref={caroucel} className="page-lifter__university">
            <University {...contentfulPageLifters} />
          </div>
          <div className="page-lifter__faq">
            <Faq {...contentfulPageLifters} />
          </div>
          <div className="page-lifter__followus">
            <Followus />
          </div>
        </section>
        {!openFormModal && inBanner && (
          <div onClick={() => handleToggleForm(true)}>
            <ButtonFixed isFixed={inFooter} {...contentfulPageLifters} />
          </div>
        )}
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query Page($locale: String) {
    contentfulPaginaLifters(language: { language: { eq: $locale } }) {
      subtitle_banner
      banner_title
      form_title
      bannerImg {
        fluid(maxWidth: 1920, quality: 80) {
          sizes
          src
          srcSet
          base64
          aspectRatio
        }
      }
      video_title
      video_subtitle
      video_about
      benefits_title
      benefits {
        ...SectionFragment
      }
      knowus_title
      knowus_image
      knowus_items {
        ...SectionFragment
      }
      apps_title
      apps_description
      apps_note
      mapaApp {
        file {
          url
        }
      }
      our_lifters
      our_lifters_img
      our_apps_comments {
        comment_title
        comment {
          comment
        }
        nombreCliente
        photo
      }
      question_title
      frequent_questions {
        answer {
          json
        }
        question
      }
      weare_items {
        ...SectionFragment
      }
      button_link_fixed {
        json
      }
      seo {
        ...SEOFragment
      }
    }
  }
`

Lifters.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
}

export default Lifters
