import React from "react"
import { useSelector } from "react-redux"
import { CSSTransition } from "react-transition-group"
import FormResume from "./Resume/FormResume"
import FormSteps from "./Steps/FormSteps"

const FormNewLead = ({ form_title, handleToggleForm }) => {

	const { isFormLeadSteps } = useSelector(state => state.liftersView)

	return (
		<>
			<CSSTransition in={isFormLeadSteps} timeout={500} classNames="form-lifter-container-anim">
				<div className="form-lifter-container">
					{isFormLeadSteps ?
						<div className="form-lifter-container__fade">
							<FormSteps formTitle={form_title} handleToggleForm={handleToggleForm} />
						</div> :
						<div className="form-lifter-container__fade">
							<FormResume formTitle={form_title} handleToggleForm={handleToggleForm} />
						</div>}
				</div>
			</CSSTransition>
		</>
	)
}

export default FormNewLead
