import React, { useState } from "react"
import { Row, Col, Button } from "antd"
import Slider from "react-slick"
import { useSelector } from "react-redux"

import ImgPlayStore from "../../../assets/images/google-play.png"
import marco from "../../../assets/images/marco.png"
import { useContentfulGeneral } from "../../../contenful/querys/General"

function OurApp({
  knowus_title,
  knowus_items,
  apps_title,
  apps_description,
  apps_note,
}) {
  const [indexButtonSelect, setIndexButtonSelect] = useState(0)
  const { languageISO639 } = useSelector(state => state.language)

  const { play_store: playStore } = useContentfulGeneral(languageISO639)

  const settings = {
    dots: true,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: current => setIndexButtonSelect(current),
    customPaging: i => {
      return (
        <Button className="btns--group">
          <span>{knowus_items[i].title}</span>
        </Button>
      )
    },
  }

  const redirectStore = () => {
    window.open(playStore, '_blank')
  }

  return (
    <div className="container">
      <div className="section-ourApp" data-aos="fade-up">
        <div className="section-ourApp__content">
          <div className="cont--btns">
            <div className="section-ourApp__txt">
              <h2 className="section-ourApp__txtTitle">{knowus_title}</h2>
              <div className="btns">
                <p className="section-ourApp__txtDescrip">
                  {knowus_items &&
                    knowus_items[indexButtonSelect].description?.description}
                </p>
              </div>
            </div>
          </div>

          <div className="caroucel--center">
            <div className="caroucel--phone">
              <div className="marco">
                <img className="marco-img" alt="marco" src={marco} />
              </div>
              <Slider {...settings}>
                {knowus_items?.map((item, index) => (
                  <div key={index} className="content--caroucel">
                    <img className="phone" src={item.image} alt="phone" />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>

      <Row>
        <Col span={24}>
          <div className="section-ourApp__intro">
            <h2 className="section-ourApp__introTitle">{apps_title}</h2>
            <p className="section-ourApp__introDescrip">{apps_description}</p>
            <figure>
              <img src={ImgPlayStore} alt="playStore" onClick={redirectStore} className="hand"/>
            </figure>
            <span className="section-ourApp__introNote">{apps_note}</span>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default OurApp
