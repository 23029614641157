import { useStaticQuery, graphql } from "gatsby"

export const useContenfulNewLead = lng => {
  const {
    allContentfulFormularioNuevoLead: { nodes: contenfulNewLead },
  } = useStaticQuery(graphql`
    {
      allContentfulFormularioNuevoLead {
        nodes {
          title
          language {
            language
          }
          rol {
            ...FieldFragment
          }
          course_cetification {
            ...FieldFragment
          }
          how_did_you_find_out {
            ...FieldFragment
          }
          logistics_services {
            ...FieldFragment
          }
        }
      }
    }
  `)

  const transform = {}

  contenfulNewLead.forEach(env => {
    if (env.language.language === lng) {
      Object.keys(env).forEach(key => {
        transform[key] = env[key]
      })
    }
  })
  return transform
}
