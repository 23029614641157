import React, { useState } from "react"
import ReactPlayer from "react-player"
import { Button } from "antd"

import playVideo from "../../../assets/images/play-videos.png"

function University({ weare_items }) {
  const [tabActive, setTabActive] = useState(weare_items && weare_items[0])
  const [play, setPlay] = useState(false)
  const [pause, setPause] = useState(false)

  const handleVideo = item => {
    setTabActive(item)
    setPlay(false)
    setPause(false)
  }

  const handleVideoPause = () => {
    setPlay(false)
    setPause(true)
  }

  return (
    <div className="University">
      <div className="University--content">
        <div className="caroucel-cont">
          <div className="tabs-uni">
            {weare_items?.map((item, index) => (
              <div
                key={index}
                className={`text text--${
                  tabActive.key === item.key && "active"
                }`}
                onClick={() => handleVideo(item)}
              >
                <h3>{item.title}</h3>
              </div>
            ))}
          </div>
        </div>
        {weare_items?.map(
          (item, i) =>
            tabActive.key === item.key && (
              <div className="video" key={`key${i}`}>
                <ReactPlayer
                  key={i}
                  url={item.image}
                  className="video-demo"
                  onPlay={() => setPlay(true)}
                  onPause={handleVideoPause}
                  playing={play}
                  config={{
                    youtube: {
                      playerVars: {
                        controls: 0,
                        fs: 0,
                        modestbranding: 0,
                      },
                    },
                  }}
                />
                {!play && (
                  <div className={`description ${pause && "tab-pause"}`}>
                    <Button className="play" onClick={() => setPlay(true)}>
                      <img src={playVideo} alt="play" />
                    </Button>
                    <h2 className="title">{item.title}</h2>
                    <p>{item.description?.description}</p>
                  </div>
                )}
              </div>
            )
        )}
        <div className="tabs-video">
          {weare_items?.map((item, index) => (
            <div
              key={index}
              onClick={() => handleVideo(item)}
              className={`tabs-video__item item--${index} ${
                tabActive.key === item.key && "tab-active"
              }`}
            >
              {item.title}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default University
