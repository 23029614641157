import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Form, Button, Radio, Checkbox } from "antd"
import { CloseOutlined } from '@ant-design/icons'

import { getNewLead } from "../../../../@common/storage/newLead"
import InputPhone from "../../../InputPhone/InputPhone"
import { liftersView as LiftersViewActions } from "../../../../services/LiftersView/LiftersViewActions"
import { modals as ModalsActions } from "../../../../services/Modals/ModalsActions"
import { useContentfulGeneral } from "../../../../contenful/querys/General"

const FormResume = ({ formTitle, handleToggleForm }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const { languageISO639 } = useSelector(state => state.language)
  const [formLayout, setFormLayout] = useState(t("general.natural"))
  const [storageNewLead, setStorageNewLead] = useState()
  const contentfulGeneral = useContentfulGeneral(languageISO639)
  const formItemLayout =
    formLayout === t("general.natural")
      ? { labelCol: { span: 24 }, wrapperCol: { span: 24 } }
      : null

  useEffect(() => {
    const newLead = getNewLead()
    if (newLead)
      setStorageNewLead(JSON.parse(newLead))
  }, [])


  const onFormLayoutChange = ({ layout }) => setFormLayout(layout)

  const handleSubmit = values => {
    dispatch(LiftersViewActions.setNewLead(values))
    dispatch(LiftersViewActions.setFormSteps(true))
  }

  const restartInscription = () => {
    handleSubmit(storageNewLead)
  }

  const openPrivacyPolicy = () => {
    dispatch(ModalsActions.setModals('modalPolicyPrivacity', true))
  }

  return (
    <>
      <div className="form-steps__fix">
        <h2 className="form-lifters__title">{formTitle}</h2>
        {handleToggleForm && <div className="form-steps__fixCloseCont" onClick={() => handleToggleForm(false)}>
          <div className="form-steps__fixClose" />
        </div>}
      </div>
      <div className="form-steps__content">
      <CloseOutlined className="icon-close-form-lifters icon-close-form-lifters--res" onClick={() => handleToggleForm(false)}/>
        <Form
          {...formItemLayout}
          onFinish={handleSubmit}
          layout={formLayout}
          form={form}
          initialValues={{ layout: formLayout }}
          onValuesChange={onFormLayoutChange}
        >
          <p className="form-lifters__label">{t("general.label_person_natural")}</p>
          <Form.Item
            name="type_person"
            rules={[{ required: true, message: t("form.error.required") }]}
          >
            <Radio.Group className="form-lifters__radio-group">
              <Radio.Button
                value={t("general.natural")}
                className="form-lifters__radio-groupInput"
              >
                {t("general.natural")}
              </Radio.Button>
              <Radio.Button
                value={t("general.juridical")}
                className="form-lifters__radio-groupInput"
              >
                {t("general.juridical")}
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
          <p className="form-lifters__label">{t("general.label_phone")}</p>

          <InputPhone
            FormParent={Form}
            formRef={form}
            prefixDetected
            classInputGroup="form-lifters__group-phone"
          />

          <Form.Item
            name="agreement"
            valuePropName="checked"
            className="form-lifters__labelCheck"
            rules={[
              () => ({
                validator(rule, value) {
                  if (value) return Promise.resolve()
                  return Promise.reject(t("form.error.required"))
                },
              }),
            ]}
          >
            <Checkbox>
              <p className="form-lifters__label form-lifters__labelCheck__check">
                {t('general.confirm_accept')}{' '}
                <a target="_blank" onClick={openPrivacyPolicy}>{t('form.privacy_policies')}</a>
                {/*
                ,{' '}
                <a target="_blank" href={contentfulGeneral.terms_conditions}>{t('form.terms_conditions')}</a>.
                */}
              </p>
            </Checkbox>
          </Form.Item>
          <Form.Item className="form-lifters__submit">
            <>
              <Button className="btn-custom btn-custom--primary" htmlType="submit">
                {t("button.send_and_continue")}
              </Button>
              {storageNewLead &&
                <Button className="btn-custom btn-custom--secundary" onClick={restartInscription}>
                  {t("button.resume_enrollment")}
                </Button>
              }
            </>
          </Form.Item>
        </Form>
      </div>
    </>
  )
}

export default FormResume
